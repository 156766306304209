import React from 'react'
import { Link } from 'gatsby'

const FooterMenu = () => {
  return (
    <footer className="mt-6 text-gray-700 bg-white border-t body-font">
      <div className="container flex flex-col flex-wrap p-5 py-10 mx-auto lg:px-20 md:items-center lg:items-start md:flex-row md:flex-no-wrap ">
        <div className="flex flex-wrap flex-grow mx-auto mt-10 -mb-10 text-left md:mt-0 ">
          <div className="w-full lg:w-1/4 md:w-full">
            <h1 className="mb-8 text-2xl font-bold text-center text-blue-800 lg:text-left lg:text-2xl title-font">
              Your greatest weakness shall be your greatest strength.
            </h1>
          </div>
          <div className="w-full text-right lg:w-1/4 md:w-1/2 ">
            <h1 className="mb-3 text-sm font-semibold tracking-widest text-blue-700 uppercase title-font">
              Programs
            </h1>
            <nav className="mb-10 list-none">
              <li>
                <Link
                  to="/pricing"
                  className="text-sm text-gray-600 hover:text-gray-800"
                >
                  Pricing
                </Link>
              </li>
              <li>
                <Link
                  to="/specialties"
                  className="text-sm text-gray-600 hover:text-gray-800"
                >
                  Specialities
                </Link>
              </li>
              <li>
                <Link
                  to="/action-based-learning-consulting"
                  className="text-sm text-gray-600 hover:text-gray-800"
                >
                  Action Based Learning
                </Link>
              </li>
            </nav>
          </div>
          <div className="w-full text-right lg:w-1/4 md:w-1/2">
            <h1 className="mb-3 text-sm font-semibold tracking-widest text-blue-700 uppercase title-font">
              Links
            </h1>
            <nav className="mb-10 list-none">
              <li>
                <Link
                  to="/mystory"
                  className="text-sm text-gray-600 hover:text-gray-800"
                >
                  My Story
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className="text-sm text-gray-600 hover:text-gray-800"
                >
                  Contact
                </Link>
              </li>
              <li>
                <Link
                  to="/privacy-policy"
                  className="text-sm text-gray-600 hover:text-gray-800"
                >
                  Privacy Policy
                </Link>
              </li>
            </nav>
          </div>
          <div className="w-full text-right lg:w-1/4 md:w-1/2">
            <h1 className="mb-3 text-sm font-semibold tracking-widest text-blue-700 uppercase title-font">
              Tools
            </h1>
            <nav className="mb-10 list-none">
              <li>
                <Link
                  to="/fibromyalgia-&-chronic-pain"
                  className="text-sm text-gray-600 hover:text-gray-800"
                >
                  Fibromyalgia and Chronic Pain
                </Link>
              </li>
              <li>
                <a
                  href="http://www.wpcalculators.com/health-and-fitness/macro-calculator/"
                  className="text-sm text-gray-600 hover:text-gray-800"
                >
                  Macro Calculator
                </a>
              </li>
              <li>
                <Link
                  to="/smoothies"
                  className="text-sm text-gray-600 hover:text-gray-800"
                >
                  Smoothies
                </Link>
              </li>
              <li>
                <Link
                  to="/gut-health"
                  className="text-sm text-gray-600 hover:text-gray-800"
                >
                  Gut health
                </Link>
              </li>
              <li>
                <Link
                  to="/intuitive-eating"
                  className="text-sm text-gray-600 hover:text-gray-800"
                >
                  Intuitive Eating
                </Link>
              </li>
              <li>
                <Link
                  to="/resources"
                  className="text-sm text-gray-600 hover:text-gray-800"
                >
                  Resources
                </Link>
              </li>
            </nav>
          </div>
        </div>
      </div>
      <div className="bg-blueGray-900">
        <div className="container flex flex-col flex-wrap px-5 py-6 mx-auto lg:px-20 sm:flex-row">
          <span className="inline-flex justify-center mt-2 sm:mt-0 sm:justify-start">
            <a className="text-white hover:text-blue-500">
              <svg
                fill="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z" />
              </svg>
            </a>
            <a className="ml-4 text-white hover:text-blue-500">
              <svg
                fill="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z" />
              </svg>
            </a>
            <a className="ml-4 text-white hover:text-blue-500">
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <rect width="20" height="20" x="2" y="2" rx="5" ry="5" />
                <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01" />
              </svg>
            </a>
          </span>
        </div>
      </div>
    </footer>
  )
}

export default FooterMenu
