/* eslint-disable jsx-a11y/label-has-associated-control */
/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import '../styles/layout.css'
import FooterMenu from './layout/FooterMenu'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <>
      <nav className="nav flex flex-wrap items-center justify-between px-4 border-b-2 border-gray-100 pb-3">
        <div className="flex flex-no-shrink items-center mr-6 py-3 text-grey-darkest top-nav_logo">
          <Link to="/">
            <span className="top-nav_logo1">Fitness and Spirit</span>
            <span className="top-nav_logo2">MIND.BODY.SPIRIT</span>
          </Link>
        </div>

        <input className="menu-btn hidden" type="checkbox" id="menu-btn" />
        <label
          className="menu-icon block cursor-pointer md:hidden px-2 py-4 relative select-none"
          htmlFor="menu-btn"
        >
          <span className="navicon bg-grey-darkest flex items-center relative" />
        </label>

        <ul className="menu border-b md:border-none flex justify-end list-reset m-0 w-full md:w-auto">
          <li className="border-t list-none md:border-none">
            <Link
              to="/mes"
              className="block md:inline-block px-4 py-3 no-underline text-grey-darkest hover:text-grey-darker"
            >
              Why MES
            </Link>
          </li>

          <li className="border-t list-none md:border-none">
            <Link
              to="/specialties"
              className="block md:inline-block px-4 py-3 no-underline text-grey-darkest hover:text-grey-darker"
            >
              Specialties
            </Link>
          </li>

          <li className="border-t list-none md:border-none">
            <Link
              to="/pricing"
              className="block md:inline-block px-4 py-3 no-underline text-grey-darkest hover:text-grey-darker"
            >
              Pricing
            </Link>
          </li>
          <li className="border-t list-none md:border-none">
            <Link
              to="/mystory"
              className="block md:inline-block px-4 py-3 no-underline text-grey-darkest hover:text-grey-darker"
            >
              My Story
            </Link>
          </li>
          <li className="border-t list-none md:border-none">
            <Link
              to="/contact"
              className="block md:inline-block px-4 py-3 no-underline text-grey-darkest hover:text-grey-darker"
            >
              Contact
            </Link>
          </li>
        </ul>
      </nav>
      <div>{children}</div>
      <FooterMenu />
    </>
  )
}

export default Layout
